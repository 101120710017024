<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex xs12 md8 offset-md2>
          
         
            <div slot="widget-content">
              <v-card>
                <v-form ref="form" v-model="siteInfo.valid" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>

                        <v-autocomplete
                          v-model="forms.companyName"
                          :items="items"

                          label="업체선택"

                          :rules="options.companyRules"
                          
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12>
                        <v-select
                          v-model="forms.options"
                          :items="select"
                          attach
                          chips
                          label="견적기 특징"
                          multiple
                          :rules="options.optionsRules"
                          persistent-hint
                          hint = "업로드할 엑셀파일의 특징,동일업체라도 여러개의 엑셀파일이 존재하므로 해당 특징으로 지정할것"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 class="text-xs-center text-sm-center text-md-center text-lg-center">
                        <img :src="imageUrl" height="150" v-if="imageUrl"/>
                        <v-text-field 
                          label="업로드할 엑셀 파일 or *" 
                          @click='pickFile'
                          v-model='forms.imageName'
                          prepend-icon='mdi-paperclip'
                          :rules="options.excelRules"
                          ></v-text-field>
                        <input
                          type="file"
                          style="display: none"
                          ref="image"
                          accept="*/*"
                          @change="onFilePicked"
                          >
                      </v-flex>
                      
                      <v-card-actions class="justify-center">
                        <v-btn color="primary"  @click="onSave" :disabled="!siteInfo.valid" :loading="loading">저장</v-btn>
                        <v-btn color="primary"  text :to="{name: 'user.excel.index'}">취소</v-btn>
                      </v-card-actions>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card>
            </div>
          
        </v-flex>
       
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment-timezone'
export default {
  components: {},
  data () {
    return {
      selectedItem: '',
      search: '',
      items: ['현대캐피탈','CNH캐피탈','한국캐피탈','우리금융','엠캐피탈','농협','신한카드','우리카드','DGB','우리캐피탈','오릭스','BNK','조이','메리츠','롯데캐피탈','미래에셋','KDB산은','삼성카드'],
      /*
        {value: 1, text: '우리금융'},
        {value: 2, text: '엠캐피탈'},
        {value: 3, text: '농협'},
        {value: 4, text: '신한카드'},
        {value: 5, text: '우리카드'},
        {value: 6, text: 'DBG'},
        {value: 7, text: '오릭스'},
        {value: 8, text: 'BNK'},
        {value: 9, text: '조이'},
        {value: 10, text: '메리츠'},
        {value: 11, text: '롯데캐피탈'},
        {value: 12, text: '미래에셋'},
        {value: 13, text: 'KDB산은'},
        {value: 14, text: '삼성카드'},
      ],
      */
      select: ['국산', '수입','렌트','리스','전기'],
      loading:false,
      message:'',
      siteInfo: {
        valid: false,
        //dialog: false
      },
      formOptions: {
        isLoading: false,
        siteName: '',
        detail: '',
        show1: false,
        blogId: '',
        cafeId: ''
      },
      company: '',
      //dialog: false,
      imageFile: '',
      imageUrl: '',
      forms: {
        email: this.$store.getters.user.email,
        uid: this.$store.getters.user.uid,

        companyName: '',

        options: [],
        currentTime: 0,

        imageMemo: '',
        imageName: '',
        createdAt: '',
        updatedAt: '',
        fileBucket: '',
        src: '',
      },
      options: {
        companyRules: [
          v => !!v || '업체명을 선택하세요'
        ],
        optionsRules: [
          v => !!v.length > 0 || '특징을 하나 이상 선택하세요'
        ],
        excelRules: [
          v => !!v.length > 0 || '파일을 선택하세요'
        ],
        
      },
      query: {
        firebase: this.$store.getters.firebase,
        ref: this.$store.getters.ref,
        thisRef: '',
        user: this.$store.getters.user,
        queryFirst:'',
        queryNext:'',
        refDB:''
      },
      table: 'excel'
    }
  },
  created () {},
  mounted () {},
  methods: {
    
    pickFile() {
      this.$refs.image.click()
    },
    onFilePicked (e) {
      const files = e.target.files
      this.$log.warn(files)
      if(files[0] !== undefined) {
          this.forms.imageName = files[0].name
          if(this.forms.imageName.lastIndexOf('.') <= 0) {
              return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
              this.imageUrl = fr.result
              this.imageFile = files[0] // this is an image file that can be sent to server...
          })
      } else {
          this.forms.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
      }
    },    
    onFormCheck () {
      if (this.$refs.form.validate()) {        
        return true
      }
      return false
    },    
    onSave () {
      if (!this.onFormCheck()) {
        return
      }
      this.loading=true
      this.forms.created = moment().format()
      this.onUploadFile()
      // this.onSaveData()
    },
    onSaveData () {
      const _this = this
      this.formOptions.isLoading = true
      this.forms.currentTime = moment().toDate().getTime()
      this.forms.createdAt= moment().format()
      this.forms.updatedAt= moment().format()

      const refDB = this.query.firebase.firestore().collection(this.table)
      refDB.add(this.forms)
      .then((res) => {
        _this.$log.warn('success upload',res)
        _this.$toasted.show('등록되었습니다', {type: 'success'})
        _this.loading = false
        this.$router.push({ name: 'user.excel.index'})

      }).catch((e) => {          
        _this.$log.warn('payload',e)
        _this.loading = false
      })

    },
    onUploadFile() {

      let _this = this
      const fileval = 'excel' + '/' + moment().format('YYYY-MM-DD') + '/' + _this.$shortid.generate()

      let storageRef = _this.query.firebase.storage().ref(fileval)
      const uploadTask = storageRef.put(_this.imageFile)
      uploadTask.on('state_changed', () => {
      }, error => {
          _this.$log.warn('upload error1 : ' + error.message)
          this.loading=false
      }, () => {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          _this.forms.fileBucket = fileval
          _this.forms.src = downloadURL

          _this.onSaveData()
          })
      })
     
      
    },
    

  }
};
</script>
<style >
  

  .v-text-field__prefix {
    width: auto;

  }
</style>